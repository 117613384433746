.agnt-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f1f1f9;
  color: #000;
  height: 80px;
  padding: 0 20px;
  box-shadow: 0 2px 2px -2px rgba(0,0,0,.2);
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
}

.logo svg{
  font-size: 2rem;
}

.nav-links {
  display: flex;
  justify-content: space-around;
  width: 50%;
}

.nav-links-mobile {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 60vh;
  position: absolute;
  top: 80px;
  /* left: ${({ isMobile }) => (isMobile ? 0 : '-100%')}; */
  background-color: #fff;
  transition: all 0.5s ease;
  z-index: 3;
}

.nav-links{
  display: none;
}
.nav-links-mobile li {
  text-align: center;
  margin: 10px 0;
}

.mobile-menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}
.agnt-navbar li{
  list-style: none;
  border-bottom: 1px solid #f1f1f9;
}


@media screen and (max-width: 960px) {
  .nav-links-mobile a {
    font-size: 1rem;
    float: center;
  padding-right: 30px;
  text-decoration: none;
  }
  .agnt-navbar {
    padding: 0 30px;
    background-color: #fff;
  }

  .nav-links {
    display: none;
  }

  .mobile-menu-icon {
    display: block;
    background-color: transparent;
    border: none;
  }
  .agnt-name{
    display: none
  }
  .agnt-name-mob{
    align-items: center;
    justify-content: space-between;
  }
  
.agnt-navbar .dropdown{
  display: none;
}
.agnt-name-mob .dropdown{
  display: block !important;
}
.agt-nav-menu.actv a{
  color: #ff8504 !important;
}
.agt-nav-menu.inact a{
  color: #000 !important;
}
.agt-nav-menu.actv, .agt-nav-menu:hover{
  background-color: #f1f1f9;
  border-left: 5px solid #ff8504;
  border-right: 2px solid #ff8504;
}
}
