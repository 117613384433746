.icon-card {
    background: #F3F3F3;
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
width: 100px;
height: 100px;
margin: 15px;
display: flex;
justify-content: center;
align-items: center;
}



.active, .icon-card:hover {

    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 1px 1px 10px 3px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    
    
}
.icon-card.active >figure>figcaption, .icon-card:hover >figure>figcaption {
    /* font-size: 14px; */
    color: #000000;
}

.icon-card.active>figure >svg, .icon-card:hover>figure >svg{
    fill:#3247D1;
}

.icon-card >figure >svg{
height: 40px;
width: 40px;
fill: #A7A7A7;

}

.icon-card >figure>figcaption{
    font-size: 14px;
    color: #A7A7A7;
}


.icon-card >figure {
    margin: auto;
}
.side-icon-bar-container{
  position: sticky;
  top: 100px;
}
@media only screen and (max-width: 768px) {
    .side-icon-bar-container {
        display: flex;
        flex-direction: row;
    }
}
