.favorite-head{
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
    color: #fa983a;
    text-decoration: underline;
}
/* @media only screen and (min-width: 1220px)and (max-width:1700px) {
    .favorites-card {
        display: flex;
        justify-content: center;
    }
} */
    @media (min-width: 1024px) {
        .favorites-card{
           display: flex;
           justify-content: center;
        }
    }

@media (min-width:900px)and (max-width:1440px){
    .favorites-card{
        display: flex;
        justify-content: center;
    }
}

