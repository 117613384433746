.agent-navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px; */
  color: black;
  box-shadow:  2px 2px 4px gray;
  height: 80px;
  width: 100%;
}
.logo-truprops{
  cursor: pointer;
}
.agent-welcome {
  font-family: poppins;
  font-weight: 400;
  font-size: 22px;
  color: black;
}

/* LOGO */
.agent-logo {
  font-size: 32px;
}

.agent-nav-links > a {
  color: #fff;
}
/* .agent-hamburger ~ .agent-menu {
    display: block;
  } */

.agent-menu {
  display: flex;
  gap: 1em;
  font-size: 18px;
}
.agent-menu > li:hover {
  border-radius: 5px;
  transition: 0.3s ease;
}

.agent-menu > li {
  padding: 5px 14px;
}

.agent-menu > li {
  list-style: none;
}

.agent-name {
  font-weight: bold;
  font-size: 23px;
  margin-top: 5px;
}
.agent-name1 {
  font-weight: bold;
  font-size: 28px;
  /* margin-top: 10px; */
}
.cust-location {
  color: #6c6cfd;
}
/* .agent-a {
  text-decoration: none;
} */
/* CHECKBOX HACK */
/* input[type="checkbox"] {
  display: none;
} */
/*HAMBURGER MENU*/
.agent-hamburger {
  display: none;
  font-size: 24px;
  /* user-select: none; */
}
/* .agent-hamburger:disabled ~ .agent-menu {
  display: block;
} */
@media (max-width: 768px) {
  .agent-welcome {
    font-family: poppins;
    font-weight: bolder;
    font-size: 14px;
    color: black;
  }
  .agent-menu {
    display: none;
    position: absolute;
    right: 0;
    left: 0;
    text-align: center;
    padding: 16px 0;
  }
  .agent-menu > li:hover {
    display: inline-block;
    transition: 0.3s ease;
  }

  /* .agentmenu li + li {
    margin-top: 12px;
  } */

  /* input[type="checkbox"]:enabled ~ .agent-menu {
    display: block;
  } */

  .agent-hamburger {
    display: block;
  }
  .agent-hamburger:enabled ~ .agent-menu {
    display: block;
  }
}
