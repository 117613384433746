* {
  box-sizing: border-box;
}
.form_div_container {
  width: 30%;
  /* border-radius: 20px; */
  margin: 2em auto;
  padding: 2em;
  /* box-shadow: 2px 0px 10px -1px rgba(0, 0, 0, 0.75); */
  /* -webkit-box-shadow: 2px -1px 13px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px -1px 13px 0px rgba(0, 0, 0, 0.75); */
}
.pro_pic_con {
  margin: 0 auto;
  background-color: var(--secondary-color);
  width: 150px;
  height: 150px;
  border-radius: 75px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form_container {
  display: flex;
  flex-direction: column;
}
.btn1 {
  border: 0;
  background-color: #fa983a;
  border-radius: 10px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding: 0.5em 1em;
  color: #fff;
}
.btn1:hover {
  background-color: var(--secondary-color);
}
.form_field_con {
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  margin: 1em 0;
}
.form_field_con input {
  /* border-radius: 10px; */
  padding: 0.5em;
 color: #6C757D;
}
