.agree-main{
  background-color: #f1f1f9;
}

.agree-form-header{
  font-size: 32px;
  font-weight: 600;
  padding: 10px;
  display: flex;

}
.agree-form-subheader{
  font-size: 12px;
  color: #575756;
  padding: 10px;
}

.agree-form{
  /* border: 1px solid rgb(185, 183, 183); */
  background-color: #fff;
  padding: 30px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(2.6px);
  -webkit-backdrop-filter: blur(2.6px);
  border: 1px solid rgba(255, 255, 255, 0.3);

  height: 75vh;
  overflow: scroll;
}
.agree-form-field{
  display: flex;
  flex-direction: column;
  padding: 10px;

}
.agree-form-field-check{
  padding: 20px 10px;
}

.agree-form-field-check label{
  padding-left: 10px;
  font-weight: 600;
}
.agree-form-field-check input[type="checkbox"]{
  height: 20px;
  width: 20px;
  accent-color: #6c6cfd;
  /* filter: sepia(100%) brightness(80%) hue-rotate(170deg) saturate(70%) contrast(300%); */
}

.agree-form-field label{
  padding-bottom: 10px;
  font-size: 12px;
 
}
.agree-form-field input, .agree-form-field select{
 /* height: 50px; */
 border-bottom: 1px solid #f1f1f9 !important;
 background-color: transparent;
 border: none;
 font-size: 14px;
 font-weight: 600;
}
.agree-form-field input:focus, .agree-form-field-item input:focus{
outline: none;
 }

 .agree-form-field ::placeholder{
  color: rgb(131, 130, 130);
 }
 .agree-btn-container{
  text-align: center;
  padding: 20px 10px 10px 10px;
 }
 .agree-btn{
  background-color: #6c6cfd;
  color: #fff;
  border-radius: 5px;
  border: none;
  margin-right: 50px;
  font-size: 20px;
  font-weight: 600;
  padding: 5px;
  width: 100px;

 }

 .agree-main{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 93vh;

 }
 .agree-left{
  width: 50%;
  padding: 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
 }
 .agree-form-container{
  width: 40%;
  margin: 50px 10px;
  /* border: 2px solid rgb(185, 183, 183);
  background-color: #fff;
  border-radius: 5px; */
  padding: 20px;
  /* height: 80vh; */
 }

/* item list */

.agree-form-field-item, .item-container{
  display: flex;
  flex-direction: row;
  border: 1px solid rgb(185, 183, 183);
  width: 350px;
  border-radius: 5px;
  justify-content: space-between;
}
.quantity{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: center; */
}

.agree-form-field-item input{
  /* height: 50px; */
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 600;
  padding: 0px 10px;
}
.agree-form-field-item button{
  background-color: #ff890f;
  color: #fff;
  border-radius: 4px;
  border: none;
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  width: 35px;
}

.item-container{
  margin: 20px 0px;
  padding: 0px 10px;
}

.quantity button{
  background-color: #d4d4d3;
  color: rgb(34, 34, 34);
  border-radius: 10px;
  border: none;
  width: 30px;
  padding: 0px;
  height: 30px;
  color: #6c6cfd;

}
.quantity *, .item-list{
  padding: 10px;
  margin: 0px 2px;
}
.item-list{
  font-size: 14px;
  font-weight: 600;
  background-color: transparent;
  text-align: center;
}
.agree-form-field .chip-btn{
  font-size: 14px;
  padding: 4px !important;
  font-weight: 600;
  text-align: center;
  /* margin: 0px 10px !important; */
}
.error{
  color: rgb(248, 58, 58);
  font-size: small;
}

.required{
  color: red;
}

@media only screen and (max-width:768px){
  .agree-main{
    display: flex;
    flex-direction: column !important;
    align-items: center;
     }
     .agree-left{
      width: 100%;
    
     }
     .agree-form-container{
      width: 100%;
      margin: 50px 10px;
      /* border: 2px solid rgb(185, 183, 183);
      background-color: #fff;
      border-radius: 5px; */
      padding: 20px;
      /* height: 80vh; */
     }
     .agree-left>svg{
      width: 90%;
      height: 300px;
     }
     .agree-form{
      height: 100%;

     }
}
