.img-container1 {
  width: 400px;
  height: 300px;
  overflow: hidden;
}

.img-container1 > img {
  height: 100%;
  width: 100%;
  animation: appear 1s ease;
}

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.property-card-container {
  position: relative;
}

.property-card-container > .icons {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 20;
  display: flex;
  flex: row;
}
.property-card-container > .icons > div {
  margin: 10px;
}

.property-card-container > .icons > div:hover {
  transform: scale(1.2);
}
.card-body.property-card-detail {
  background-color: #ff8504;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.details {
  height: 70px;
  margin-bottom: 7px;
  color: white;
}

@media only screen and (max-width:768px){
  .img-container1{
    width: 100%;
  }
}