.checkOut {
  margin-top: 5%;
  margin-left: 40%;
  background-color: var(--secondary-color);
  border: none;
  border-radius: 5px;
  width: 20%;

}
.checkOut:hover {
  cursor: pointer;
  background-color: var(--primary-color);
  color: white;
}
td > input {
  border: 0;
}
.img_con {
  margin: 2em 0;
  background: radial-gradient(256.93% 236.2% at 34.38% -24.29%, #FF8504 32.99%, #FF9B4B 68.47%);
  border-radius: 50px 5px  50px;
}
.logo_img {
  display: block;
  width: 300px;
  margin: auto;
  height: auto;
}
