body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
  overflow: -moz-scrollbars-none; /* Old versions of Firefox */
}
body::-webkit-scrollbar {
  display: none; /* Chrome */
}
.hero__container {
  width: 80%;
  margin: 1em 2em;
  margin: 0 auto;
}
.card__container {
  padding: 0.5em 1em;
  width: 100%;
  border: 1px solid #cac9c9;
  /* background-color: #dfdbdb; */
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  margin-bottom: 0.5em;
}
.question__container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-direction: column; */
  gap: 1em;
  transition: all 1s cubic-bezier(0, 1, 1);
}
.plus__btn {
  font-size: 1.5rem;
  font-weight: 700;
  cursor: pointer;
}

.header__container {
  margin: 2em 0;
}
.cards__container {
  /* background-color: #cac9c9; */
  border-radius: 20px;
  padding: 1em;
}
.question_ {
  font-weight: 700;
}
.answer--expanded {
  max-height: 500px;
  transition: all 0.5s ease-out;
}

.ptag {
  transform-origin: 50% 50%;
  transform: translate(0px, 0px);
}
