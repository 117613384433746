.stamp-header{
  width: 100%;
  object-fit: cover;
}
.prev-header, .center-text{
  text-align: center;
  margin: 10px 0px;
}
ol li{
  list-style: numeric;

  padding-bottom: 10px;
}

.prev-doc{
  height: 80vh;
  overflow: scroll;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 10px;
}

.prev-doc span, .current-day{
  background-color: #f1f1f9;
  border-radius: 10px;
  padding: 3px 9px;
  font-weight: 600;
  color: #6c6cfd;
  width: max-content;

}
.agree-whitespace{
  display: none;
}
.annex-tab table, .annex-tab th, .annex-tab td{
  border: 1px solid black;
  border-collapse: collapse;
}
@media print{
  .stamp-header, .agree-header, .agree-left, .agree-btn-container {
    display: none;
  }
  .agree-whitespace{
    display: block !important;
    margin-top: 50%;
  }
  .printdoc{
    width: 100%;
  }
  @page{margin-top: 0; margin-bottom: 0;}
  body{margin-top: 1.6cm; margin-bottom: 1.6cm;}
  body *:not(.agree-form-container){
    display: none;
    /* background-color: aqua; */
  }
  /* .prev-doc{
    display: block !important;
  } */
}