:root {
  --primary-color: #fa983a;
  --secondary-color: #6c6cfd;
  --success-color: #4caf50;
  --bg-color-1: #f1f1f9;
}
.web-bg {
  background-color: #ffffff;
  height: 100%;
}
@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: poppins !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* //* Modal */
.modal-rounded-30 {
  border-radius: 30px !important;
}

.f-48 {
  font-size: 48px;
}
.f-26 {
  font-size: 26px;
}
.f-24 {
  font-size: 24px;
}
.f-22 {
  font-size: 22px;
}
.f-20 {
  font-size: 20px;
}
.f-12 {
  font-size: 12px;
}
.f-14 {
  font-size: 14px;
}
.f-16 {
  font-size: 16px;
}
.f-18 {
  font-size: 18px;
}

.number-field-disable-stepper::-webkit-inner-spin-button,
.number-field-disable-stepper::-webkit-outer-spin-button,
input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

/* *letter spacing */
.ls-1 {
  letter-spacing: 1px;
}
.ls-2 {
  letter-spacing: 2px;
}
.ls-3 {
  letter-spacing: 3px;
}

.no-border {
  border: none;
  outline: 0px !important;
}
.cursor-p {
  cursor: pointer;
}
.primary-bg {
  background-color: var(--primary-color) !important;
}
.on-primary-text {
  color: #fff;
}
.primary-text {
  color: var(--primary-color) !important;
}

.secondary-bg {
  background-color: var(--secondary-color) !important;
}
.secondary-text {
  color: var(--secondary-color) !important;
}
.on-secondary-text {
  color: #fff;
}
.success-bg {
  background-color: var(--success-color) !important;
}
.success-text {
  color: var(--success-color) !important;
}
.on-success-text {
  color: #fff;
}

/* font-bold */
.f-b {
  font-weight: bold;
}
.f-l {
  font-weight: normal;
}

.secondary-color {
  color: var(--secondary-color);
}

/* * input border * */
.hide-input-border {
  border: 0px;
}
.hide-background {
  background: transparent;
}
.hide-input-border:focus {
  outline: none;
}

.bg-color-1 {
  background-color: var(--bg-color-1);
}

.t-btn {
  cursor: pointer;
}

.shadow-preset-1 {
  -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24);
}

.pagination-container{
  text-align: center;
  padding: 10px;
  z-index: 10;
}
.pagi-btn{
  margin: 5px;
  height: 40px;
  width: 40px;
  outline: none;
  border: none;
  border-radius: 5px;
  background-color: #6c6cfd;
  color: #fff;
  padding: 0.1em;

}
.pagi-btn:disabled, .pagi-btn[disabled]{
background-color: #fff;
color: #6c6cfd;
border: 1px solid #6c6cfd;
}


.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 18px;
  height: 18px;
  background-color: #000;
  border: 2px solid #fff;
  border-radius: 100%;
  user-select: none;
  transform: translate(-50%, -50%);
}
.marker:hover {
    z-index: 1;
  }

  input[type=range] {
    -webkit-appearance: none;
    width: 100%;
    margin: 13.5px 0;
  }
  
  input[type=range]:focus {
    outline: none;
  }
  
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: #ddd;
    border-radius: 5px;
  }
  
  input[type=range]::-webkit-slider-thumb {
    border: none;
    height: 30px;
    width: 20px;
    border-radius: 10%;
    background: #6c6cfd;
    margin-top: -5.5px;
    -webkit-appearance: none;
    cursor: pointer;
  }
  
  #slider-value {
    display: block;
    margin-top: -25px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
  }
  
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
  }
  
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: #ddd;
    border-radius: 5px;
  }
  
  input[type=range]::-moz-range-thumb {
    border: none;
    height: 30px;
    width: 20px;
    border-radius: 10%;
    background: #6c6cfd;
    margin-top: -5.5px;
  }
  
  input[type=range]::-ms-track {
    width: 100%;
    height: 10px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    color: transparent;
  }
  
  input[type=range]::-ms-fill-lower {
    background: #ddd;
    border-radius: 10px;
  }
  
  input[type=range]::-ms-fill-upper {
    background: #ddd;
    border-radius: 10px;
  }
  
  input[type=range]::-ms-thumb {
    border: none;
    height: 30px;
    width: 20px;
    border-radius: 10%;
    background: #6c6cfd;
    margin-top: -5.5px;
    -ms-appearance: none;
  }
  
  input[type=range]:focus::-ms-fill-lower {
    background: #ccc;
  }
  
  input[type=range]:focus::-ms-fill-upper {
    background: #ccc;
  }
  
  input[type=range]:focus::-ms-thumb {
    background: #6c6cfd;
  }
  