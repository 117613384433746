.custom-file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.custom-file-input + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

.custom-file-input:focus + label,
.custom-file-input + label:hover {
  background-color: red;
}
.custom-file-input + label {
  cursor: pointer; /* "hand" cursor */
}
.custom-file-input:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.custom-file-input + label * {
  pointer-events: none;
}

.custom-file-input-label > div {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-file-input-label > div > div {
  font-size: 0.75em;
  border-radius: 10px;
  border-width: 4px;
  width: 100px;
  height: 100px;
  border-color: grey;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  opacity: 0.3;
  text-align: center;
}
.image-container {
  display: flex;
  justify-self: center;
  align-items: center;
  width: 200px;
  overflow: hidden;
  height: 200px;
}

.task-card {
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  border-radius: 20px;
  /* height: 200px; */
  border: 2px solid rgb(243, 242, 242);
  box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin: 20px;
}
.img-cont {
  width: 213px;
  display: inline-block;
  vertical-align: top;
  height: 260px !important;
}

.stub {
  background-size: 50%;
}

.prev-container-right {
  margin: 80px 0 23px 12px;
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  white-space: normal;
  top: 80px;
  width: 400px;
  height: 100%;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px -1px rgba(112,108,112,1);border-radius: 10px;
  
}

/* style from raj design */
.card-progress {
  overflow: auto;
  border-radius: 30px;
  /* height: 250px; */
  /* width: 300px; */
}

.cust-location {
  color: #6c6cfd;
}

.container.prop-listing-box{
  /* top: 100px; */
  
}
.listing-form{
  /* height: 80vh;
  overflow: scroll; */
  padding: 0px 50px 50px;
  width: 60%;
  margin-top: 100px;
}
.listing-heading, .text-center>h2 {
  /* border-bottom: solid 2px #6c6cfd; */
  background-color: #6c6cfd;
  color: #fff;
  border-radius: 5px;
  padding: 5px;
}
.listing-container{
  background: #FFFFFF;
  box-shadow: 0px 0px 6px -1px rgba(112,108,112,1);border-radius: 10px;
padding: 20px 10px;
margin: 30px 0px;
}
.chip-btn{
  margin: 10px;
}
.listing-modal-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.listing-btn-selected{
  color: #fff;
  background-color: #6c6cfd;
}
.listing-btn-default{
  color: #6c6cfd;
  background-color: #fff;
  border: solid 1px #6c6cfd;
}



.loading-overlay0{
  position: fixed;
  flex-direction: column;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 300;
  height: 100vh;
  width: 100vw;
}

.current-loc{
  background-color: #ffffff;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
border-radius: 2px;
width: 40px;
height: 40px;
  padding: 3px;
  float: right;
  cursor: pointer;
  text-align: center;
}
.current-loc img{
  height: 30px;
  width: 30px;
  
}
@media only screen and (max-width:768px) {
  .container-right{
    display: none;
  }
  .prop-listing-box{
    padding: 20px;
    width: 90%;
  }
  .listing-form{
    padding: 5px !important;
    height: 100%;
    width: 90%;
    overflow: none;
    margin: 5px !important;
    margin-top: 100px;
  }
  .prev-container-right{
    display: none;
  }
  .col{
    flex: none !important;
    margin-bottom: 3px;
    border-bottom: thin solid rgba(241, 244, 241, 0.8);
    width: max-content;
  }
  .listing-heading, .text-center>h2{
    font-size: 22px;
  }
  .chip-btn{
    margin: 5px !important;
  }
  
  .listing-modal-row{
    display: flex;
    flex-direction: column;
  }
}