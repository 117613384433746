.history-table-head{
  background: #D9D9D9;
}
.Aborted , .Failure {
  border-radius: 0.5em;
border: 1.5px solid #DC3545;
background: #FFEDEF;
padding: 0.2em;
color: #DC3545;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: fit-content;
}
.Success{
  border-radius: 0.5em;
border: 1.5px solid #198754;
background: #E9FFF5;
padding: 0.2em;
color: #198754;
font-family: Poppins;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: normal;
width: fit-content;
}

@media (max-width: 767px) {
  .table-responsive {
    overflow-x: auto;
  }
}