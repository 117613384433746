.dashboard-card {
    height: 240px;
    /* width:400px; */
    box-shadow: 0px 4px 18px 4px rgba(0, 0, 0, 0.25);
border-radius: 11px;
color: #FFFFFF;
padding: 10px;
margin: 20px;
position: relative;
}
/* .adc-icons{
  position: absolute;
  top: 20px;
  right: 50px;
} */

.dashboard-card-title {
    font-weight: 500;
    font-size: 20px;
    padding: 10px;
}

.total-count {
    font-weight: bold;
    font-size: 40px;
    padding: 10px;
    width: 50%;
}

.view-all-btn, .adc-icons {
    float: right;
}

.sale-rent * svg {
    height: 25px;
    width: 25px;
}

.sale-rent {
    display: flex;
    flex-direction: row;
    width: 50%;
    justify-content: space-between;
    padding: 10px 20px;
    
}

.sale>div, .rent>div {
    font-size: 16px;
}

.rent-count, .sale-count
{
    font-weight: 700;
    font-size: 20px;
}

.view-all-btn {
    padding: 10px;
}

.view-all-btn>span>svg {
    filter: drop-shadow(5px 4px 3px rgba(0, 0, 0, 0.25));
}

.bg-c-orange {
    background-color: #F69736;
}
.bg-c-blue {
    background-color: #6C6CFC;
}
.bg-c-green {
  background-color: #31b57d;
}
.adc-icons svg{
  height: 50px;
  width: 50px;
}
.dashboard-card svg path{
  fill: #fff;
}

@media only screen and (max-width:768px){
  .dashboard-card:nth-child(1) {
    background-color: #fff !important;
    color: #939393;
    box-shadow: 1px 1px 1px 3px #939393;
  }
  .dashboard-card:nth-child(1) svg path{
    fill: #939393;
  }
  .dashboard-card:nth-child(2) {
    background-color: #fff !important;
    color: #F69736;
    box-shadow: 1px 1px 1px 3px #f5b576;
  }
  .dashboard-card:nth-child(2) svg path{
    fill: #F69736;
  }
  .dashboard-card:nth-child(3) {
    background-color: #fff !important;
    color: #6C6CFC;
    box-shadow: 1px 1px 1px 3px #b5b5fc;
  }
  .dashboard-card:nth-child(3) svg path{
    fill: #6C6CFC;
  }
  .dashboard-card:nth-child(4) {
    background-color: #fff !important;
    color: #31b57d;
    box-shadow: 1px 1px 1px 3px #d0f6e6;
  }
  .dashboard-card:nth-child(4) svg path{
    fill: #31b57d;
  }
}