.properties-map {
  height: 70vh;
  /* width: 100%; */
  /* position: -webkit-sticky;
  position: sticky; */
  /* top: 0; */
  padding: 30px;
}

.properties, .properties-map{
  width: 50%;
}
.properties{
  overflow: scroll;
  height: 80vh;
  padding: 30px;
  /* margin-top:30px; */
}

.properties .pagination-container{
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  width: 50%;
}

.proprties-page-container{
  display: flex;
  flex-direction: row;
}
.prop-count{
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  border-bottom: solid 2px #f1f1f9;
  margin: 10px 0px;
}

.prop-count>span{
  color: #8989fd;
  font-size: 22px;
  font-weight: 600;

}
.no-prop-found{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

@media only screen and (max-width:760px){
  .proprties-page-container{
    display: flex;
    flex-direction: column-reverse !important;
  }
  .properties-map {
    /* height: 60vh; */
    /* width: 80%; */
    /* position: -webkit-sticky;
    position: sticky; */
    /* top: 0; */
    display: none;
  }
  .properties, .properties-map{
    width: 100%;
  }
}
