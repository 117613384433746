.common-prop-card:hover {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
  transition: 0.4s;
}
.prop-card-image-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.prop-card-image-holder img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}
