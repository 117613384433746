.dashboard-stats-card-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.dashboard-stats-card > .title {
  color: #88888e;
}
.dashboard-stats-card > .stat-container {
  background-color: var(--secondary-color);
  width: 120px;
  height: 120px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  border-radius: 50%;
  margin: auto;
}

.dashboard-stats-card {
  position: relative;
  width: 100%;
  margin: 20px auto;
  padding: 20px;
}
.dashboard-stats-card > .stat-card-link > .link-text {
  position: absolute;
  right: 20px;
  bottom: 20px;
  background-color: var(--success-color);
  padding: 2px 8px;
  color: #fff;
  width: auto;
  border-radius: 8px;
  cursor: pointer;
}
.plan_container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.header_plans {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.plan_hero_div {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.plan_name {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.plans {
  flex-basis: 100%;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  color: #fff;
  text-align: center;
  background-size: cover;
  background-position: center;
  margin-bottom: 20px;
}

.basic {
  /* flex-basis: calc(50% - 10px); */
  background: linear-gradient(45deg, #fe6b8b, #ff8e53);
}

.premium {
  /* flex-basis: calc(50% - 10px); */
  background: linear-gradient(45deg, #3a1c71, #d76d77, #ffaf7b);
}

.plan_type {
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
  color: #333;
}

.price_plan {
  font-size: 14px;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.start_date {
  font-size: 14px;
  color: #333;
  text-align: center;
  margin-bottom: 10px;
}

.total_limit {
  font-size: 16px;
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
}
.verify_adhaar_con {
  display: flex;
  justify-content: flex-end;
}

@media only screen and (max-width: 768px) {
  .dashboard-stats-card-container {
    display: flex;
    flex-direction: column;
  }
  .dashboard-stats-card {
    margin: 20px auto;
    border-radius: 8px;
    border: 1px solid #88888e;
  }
}
