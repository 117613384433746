.re-home-search-bar {
  border-radius: 0px 0px 20px 20px;
  background-color: #fff;
  width: 90%;
  /* min-width: 400px; */
  /* min-height: 40px; */
  height: 50px;
  align-self: center;
  overflow: hidden;
  /* margin-top: 3rem; */
  box-shadow: 2px 2px rgba(100, 100, 100, 0.5);
}
.search-location {
  z-index: 8;
  position: absolute;
  min-width: 300px;
  width: 30%;
  max-height: 40%;
  overflow-y: scroll;

}
.map-lists{
  z-index: 8;
position: absolute;
display: block;
overflow-y: scroll;
}
/* .radio-button-search-bar{
  height: 30px;
  width: 87%;
  background-color: #000;
  opacity: 0.5;
  border-radius: 5px;
  justify-content: center;

} */
.radio-button-search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.form-check1,
.form-check2 {
  flex: 1;
  text-align: center;
  cursor: pointer;
  color: #fff;
  padding: 8px;
  border-radius: 50px;
  transition: background-color 0.3s ease;
  opacity: 0.7;
  border: 2px solid white;
}

.form-check1.selected-tab,
.form-check2.selected-tab {
  color: #fff;
  background-color: var(--secondary-color);
  opacity: 1;
  border: none;
}
/* .form-check1:hover{
  font-weight: bolder;
  border-bottom: #000;
  text-decoration: underline;
}
.form-check2:hover{
  font-weight: bolder;
  border-bottom: #000;
  text-decoration: underline;

} */
.location-search-list:hover {
  background-color: #d1d1d1;
}
.form-check-label{
  color: #fff;
}
.home-search-icon{
  color: #fff;
}

.re-home-search-bar > .location-icon {
  padding: 10px;
}
.re-home-search-bar > .location-icon > div {
  /* margin-left: 0.4rem; */
  color: #aaf;
}
.re-home-search-bar > .home-search-icon {
  background-color: var(--secondary-color);
  padding: 1rem;
}
.re-home-search-bar > .home-search-icon > div {
  margin-left: 0.4rem;
}

.home-search-input {
  margin-left: 0.3rem;
  width: 100%;
}
.home-search-input > input {
  margin-right: 0.5rem;
  width: 100%;
  border: none;
}
.home-search-input > input:focus {
  outline: 0px !important;
}

.re-home-search-bar > button {
  border: none;
  outline: 0px !important;
  height: 50px;
  border-radius: 20px 0px 20px 20px;
}

@media screen and (max-width: 768px) {

  .re-home-search-bar > .home-search-icon {
    width: 80px;
  }
  .re-home-search-bar > .home-search-icon > div,
  .re-home-search-bar > .location-icon > div {
    display: none;
  }
 
}

@media screen and (min-width: 600px) and (max-width: 750px) {
 
  .search-location{
    min-width: 450px;
  }
}