
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap');
.card-container-my-act {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    width: 20em;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
  }
  .subscribed-button{
    /* margin: 10px;*/
  padding: 2px 5px; 
  text-align: center;
  /* text-transform: uppercase; */
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 5px;
  border: 0px;
  font-weight: 700;
  box-shadow: 0px 0px 14px -7px #f09819;
  background-image: linear-gradient(45deg, #FF512F 0%, #F09819  51%, #FF512F  100%);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  }

  .card-container-my-act:hover {
    transform: scale(1.05);
  }
  .date-container-acc{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .date-contain{
    display: flex;
    flex-direction: row;
  }
  .start-acc, .end-acc {
    display: flex;
    padding: 0.3em;
    margin: 0.1em;
    font-size: 14px;
  }
  .property-name {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
  }

  .property-date {
    font-size: 14px;
    color: #777;
    text-align: center;
    margin-bottom: 20px;
  }

  .view-btn {
    padding: 10px 20px;
    background-color: var(--secondary-color);
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  .view-btn:hover {
    background-color: var(--success-color);
  }