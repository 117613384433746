.why-section-container {
  display: flex;
  flex-direction: row;
  /* min-width: 450px; */
  margin: 24px 0;
}

.why-section-container > .section-l {
  flex: 1;
  width: 50%;
}

.why-section-container > .section-l > .icons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 560px;
  margin: auto;
}

.why-section-container > .section-r {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.why-section-container > .section-r > .text {
  font-weight: 500;
  font-size: 40px;
}
.why-section-container > .section-r > .text > .truprops {
  color: var(--primary-color);
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 48px;
}

.t-icon-container {
  width: 112px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 16px;
}

.t-icon-container > .svg {
  border-radius: 100px;
  background-color: var(--primary-color);
  display: flex;
  height: 80px;
  width: 80px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  overflow: hidden;
}
.t-icon-container > .svg > img {
  width: 100%;
}

@media only screen and (max-width: 950px) {
  .why-section-container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
  }
  .why-section-container > .section-l > .icons {
    margin: 16px;
    max-width: none;
  }

  .why-section-container > .section-l{
    width: 100%
  }
  .why-section-container > .section-r {
    width: 100%;
  }

  .why-section-container > .section-r > .text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .why-section-container > .section-r > .text > .truprops {
    margin-left: 16px;
  }
}

@media only screen and (max-width: 620px) {
  .why-section-container > .section-r > .text {
    display: flex;
    flex-direction: column;
  }
}
