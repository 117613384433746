.download_mobile {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.download_icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
}

.download_mobile > .download_icons > .dwnld {
  /* margin-left: 100px; */
  color: black;
  margin-top: 10px;
  text-align: center;
  font-size: 48px;
  font-weight: bold;
}
.download_mobile > .download_icons > .app {
  /* margin-left: 100px; */
  color: #ff8504;
  font-weight: bold;
  font-size: 80px;
}


.icons-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.mob-frame {
  height: 410px;
  width: 220px;
  border-radius: 33px;
  background-color: #ff8504;
  border-style: solid;
  border-color: #212121;
  border-width: 12px;
}

.dwld-right-col {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.mob-prop {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 90%;
}

.mob-top {
  background: #6F6C6C;
box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 6px;
height: 4px;
width: 75px
}

.main-logo > img {
  height: 55px;
  width: 165px
}

.mob-bottom {
  background: #F1F1F1;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25);
width: 40px;
height: 40px;
border-radius: 50%;
}

@media only screen and (max-width: 768px) {
.download_mobile {
  display: flex !important;
  flex-direction: column !important;
}
 .download_icons, .dwld-right-col {
  width: 100%;
 }
 .download_mobile > .download_icons > .app {
  /* margin-left: 100px; */
  color: #ff8504;
  font-weight: bold;
  font-size: 52px;
  text-align: center;
}
.icons-rows {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 30px 0px;
  padding: 20px;
  justify-content: space-around;
}
}