*{
box-sizing: content-box;
}
.header-h2{
    font-size: 1.7rem;
    margin-bottom: 1em;
}
/* .main-con {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f2f2;
  } */

  .history-card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }