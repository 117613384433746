.property-card {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  width: 80%;
  min-width: max-content;
  padding: 5px;
  justify-content: space-evenly;
  align-items: center;
  /* background-color: #f1f1f9; */
  position: relative;
  border: 2px solid #939393;
  height: max-content;
}
.right-column .property-card{
  width: 50%;
}
.property-image {
  width: 200px;
  height: 150px;
  object-fit: cover;
  border-radius: 8px 0 0 8px;
}

.property-details {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
}

.property-name {
  font-size: 18px;
  margin-bottom: 8px;
}

.property-description {
  font-size: 14px;
  margin-bottom: 8px;
  color: #939393;
}

.property-price {
  font-size: 18px;
  font-weight: bold;
}

.property-button {
  background-color: #4caf50;
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  /* margin-top: 16px; */
  align-self: flex-end;
  width: 30%;
}
.agent-btn-container{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.verify-btn{
  background-color: #6c6cfd;
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
.verify-btn:disabled{
opacity: 0.5;
}
.navigate-btn{
  background-color: #ff8608;
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
*:disabled{
  opacity: 0.7;
}
.nearby-header{
  width: 50%;
  margin: 20px auto;
}
.verify-alert{
  font-size: 12px;
  color: red;
  
}
.distance-to-prop{
  position: absolute;
  top: 10%;
left: 8%;
border-radius: 5px;
font-size: 12px;
padding: 1px 2px;
}
.del-btn-bkt svg{
  height: 30px;
  width: 30px;
}
.short{
  background-color: #21f828;
}
.medium{
  background-color: #f1f513;
}
.long{
  background-color: red;
  color: #fff;
}
.vrfy-chklst{
  margin-bottom: 10px ;
}
.vrfy-chklst input{
  margin-right: 10px;
  height: 15px;
  width: 15px;
  accent-color: #6c6cfd;
}
.agt-no-rcrd{
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width:767px) {
  .property-card{
    display: flex;
    flex-direction: column;
    width: 90% !important;
  }
  .property-image{
    width: 90%;
    border-radius: 8px !important;
    margin: auto;

  }
  .agent-btn-container{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }
.property-card{
  background-color: #fff;
}
.dashboard-container{
  background-color: #f1f1f9;
}
.distance-to-prop{
  position: absolute;
  top: 40%;
right: 7%;
border-radius: 5px;
font-size: 12px;
padding: 1px 2px;
width: max-content;
}
.sidebar{
  display: none;
}
.nearby-header{
  width: 90%;
}
.property-details{
  width: 90%;
}
}