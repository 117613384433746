.t-btn-v1 {
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px;
  -moz-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.24);
  position: relative;
}

.t-btn-v1 .label {
  font-weight: bold;
  color: #fff;
  letter-spacing: 1px;
  padding: 8px 16px;
}

.t-btn-v1 > .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.24) inset;
  -moz-box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.24) inset;
  box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.24) inset;
}

.t-btn-v1.hover:hover {
  transform: scale(1.1);
}
.t-btn-v1.secondary {
  background-color: var(--secondary-color);
}

.t-btn-v1.primary {
  background-color: var(--primary-color);
}

.t-btn-v1.success {
  background-color: var(--success-color);
}
