.navbar-top-common1 {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 10px; */
    color: black;
    box-shadow:  2px 2px 4px gray;
    height: 70px;
    width: 100%;
  }
  
  a {
    color: rgb(234, 238, 241) !important;
  }
  
  .agent-name2 {
    font-weight: bold;
    font-size: 28px;
    /* margin-top: 10px; */
  }
  .agent-welcome1 {
    font-family: poppins;
    font-weight: 400;
    font-size: 20px;
    color: black;
  }
  .agent-name3 {
    font-weight: 300;
    font-size: 20px;
    margin-top: 5px;
  }
  .agent-nav-links1 {
    display: block
  }
  /* .agent-hamburger ~ .agent-menu {
      display: block;
    } */
  
  .agent-menu1 {
    display: flex;
    gap: 1em;
    font-size: 18px;
  }
  .agent-menu1 > li:hover {
    border-radius: 5px;
    transition: 0.3s ease;
  }
  
  .agent-menu1 > li {
    padding: 5px 14px;
  }
  
  .agent-menu1 > li {
    list-style: none;
  }
  .agent-hamburger1 {
    display: none;
    font-size: 24px;
    /* user-select: none; */
  }
  .prof-drop{
    display: block;
  }
  .prof-drop-mob{
    display: none;
  }
  
  @media only screen and (max-width:760px) {
  
    /* #nav-logo{order:1} */
    #nav-searchbar{order:3}
    #nav-dropdown1{order:2}
    #nav-searchbar{
      margin-top: 10px;
    }

    
  }
  @media (max-width: 768px) {
    .agent-welcome1{
      display: none;
    }
    .prof-drop{
      display: none;
    }
    .prof-drop-mob{
      display: block;
      z-index: 20;
      background-color: #f0f0f0;
      border-radius: 10px;
      width: auto;
    }
    .agent-welcome1 {
      font-family: poppins;
      font-weight: bolder;
      font-size: 14px;
      color: black;
    }
    .agent-menu1 {
      display: none;
      position: absolute;
      right: 0;
      left: 0;
      text-align: center;
      padding: 16px 0;
    }
    .agent-menu1 > li:hover {
      display: inline-block;
      transition: 0.3s ease;
    }
  
    /* .agentmenu li + li {
      margin-top: 12px;
    } */
  
    /* input[type="checkbox"]:enabled ~ .agent-menu {
      display: block;
    } */
  
    .agent-hamburger1 {
      display: block;
    }
    .agent-hamburger1:enabled ~ .agent-menu1 {
      display: block;
    }
  }