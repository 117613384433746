.navbar-top-common {
  background-color: #ff8504;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px; */
  color: black;
  box-shadow:  2px 2px 4px gray;
  height: 100%;
  width: 100%;
}

a {
  color: rgb(234, 238, 241) !important;
}

.searchbar-nav{
  width: 50%
}
.navbar-mobile-btn{
  display: none;
}

@media only screen and (max-width:768px) {

  /* #nav-logo{order:1} */
  
  #nav-dropdown{order:2}

  #nav-searchbar{
    display: none;
  }
  .navbar-mobile-btn{
    width: 50px;
    height: 50px;
    background-color: red;
  }
}