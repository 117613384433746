.form-control {
    overflow: hidden;
   
  }
  /* phone input */

  .form-main {
    margin: 50px auto;
    padding: 30px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    /* border: 1px solid #cdcbd0; */
  /* border-color: none;
  border-radius: 10px;*/
  box-shadow: 0 .5rem 1rem rgba(0,0,0,.15) !important;;
  width: 60%;
  background-color: #ffff;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.5);
  }

  @media only screen and (max-width: 600px) {
    .form-main {
      width: 90%;
      margin: 50px auto;
    }
  }

.apply-header {
  text-align: center;
  margin-top: 10px;

  /* background-color: #FF9B4B;
  
  border-radius: 30px 30px 0px 0px; */
  
}
.form-group{
  width: 250px;
 margin-left: 120px;
}
.form-group1{
  height: 250px;
  width: 200px;
}
.save-cancel{
  margin-left: 200px;
}
.web-bg{
  background: none;
}
.form-bg {
  position: absolute;
  top:0;
  
  
}
.form-bg>.bg{
  z-index: -10;
  /* background-image: url("../../assets/Background.jpg") !important; */
  background-size: cover;
}
.apply-agent{
  height: 37rem;
 
}
.head-content{
  font-size: 30px;
}