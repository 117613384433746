.main-container {
    position: relative;
    background-image: url("../../assets/homebnr.png");
    /* url("https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8c29mYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"); */
    height: 350px;
    background-size: cover;
    /* position: absolute; */
    /* display: flex;
    flex-direction: row; */
    z-index: 1;
   
  }
  
  .banner_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* width: 100%; */
    /* height: 100%; */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0px, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%) rgba(0, 0, 0, 0.4);
    z-index: -1;
  
  }
  
  .banner-row {
    display: flex;
    flex-direction: row;
    height: 350px;
    z-index: 1;
  }
  
  .left-bnr {
    background: radial-gradient(256.93% 236.2% at 34.38% -24.29%, #FF8504 32.99%, #FF9B4B 68.47%);
    width: 50%;
    height: 350px;
    /* z-index: 1; */
    padding-top: 20px;
    border-radius: 0 45% 0 0;
    display: flex;
    justify-content: center;
  }
  
  .left-bnr-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin-top: 25%; */
  }
  .brand-logo {
    width: 294px;
  height: 101px;
  
  }
  
  .refund-header{
    margin: 50px;
  }
  .interprtation-content{
    font-size: x-large;
    font-weight: bold;
    text-decoration: underline orange;
  }
  .interprtation{
    color: orange;
    font-weight: bold;
  }