.container {
    /* min-height: 100vh; */
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 100px 0; */
    /* background-color: #111; */
  }
  .timeline {
    width: 80%; 
    height: auto;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
  }

  .timeline ul {
    list-style: none;
  }
  .timeline ul li {
    padding: 10px;
    background-color: #f5f4f3;
    color: rgb(38, 37, 37);
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .timeline ul li:last-child {
    margin-bottom: 0;
  }
  .timeline-content h1 {
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    /* margin-bottom: 10px; */
    background-color: #FF8E23;
    color: #f5f4f3;
    text-align: center;
    border-radius: 5px;
    
  }
  .timeline-content p {
    font-size: 16px;
    line-height: 30px;
    /* font-weight: 300; */
  }
  .timeline-content .date {
    font-size: 14px;
    /* font-weight: 300; */
    margin-bottom: 10px;
    letter-spacing: 2px;
  }
 
  @media only screen and (min-width: 768px) {
    /* mid-line */
    .timeline:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 100%;
      background-color: gray;
    }
    .timeline ul li {
      width: 50%;
      position: relative;
      margin-bottom: 50px;
    }
    .timeline ul li:nth-child(odd) {
      float: left;
      clear: right;
      transform: translateX(-30px);
      border-radius: 20px 0px 20px 20px;
    }
    .timeline ul li:nth-child(even) {
      float: right;
      clear: left;
      transform: translateX(30px);
      border-radius: 0px 20px 20px 20px;
    }

    /* circle */
    .timeline ul li::before {
      content: "";
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: gray;
      top: 0px;
    }
    .timeline ul li:nth-child(odd)::before {
      transform: translate(50%, -50%);
      right: -15px;
    }
    .timeline ul li:nth-child(even)::before {
      transform: translate(-50%, -50%);
      left: -45px;
    }
    .timeline-content .date {
      position: absolute;
      top: -30px;
    }
    .timeline ul li:hover::before {
      background-color: #FF8E23;
    }
  }
  