.profile-contaier {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 70%;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  border:1px solid #f3f3f3;
}
.profile-right-column{
  width: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
}

.profile-contaier p{
  display: flex;
  /* margin-right: 20px; */
  width: 100%;
  justify-content: space-between;
  border-bottom: 1px solid #f3f3f3;

}

.ad-tit {
  margin-right: 10px;
  width: 40%;
}

.ad-info {
  margin: 0;
  width: 60%;
  font-weight: bold;
}
.pro-img-container img{
  height: 100px;
  width: 100px;
  border-radius: 50%;
  border: 1px solid #f3f3f3;
  /* float: center; */
}
.pro-img-container{
  width: 80%;
  text-align: center;
  margin-bottom: 10px;
}
.et-ag-profile{
  border: none;
  border-radius: 8px;
  background-color: #3247d1;
  color: #fff;
  padding: 5px;
}
.pro-img-container svg{
  height: 80px;
  width: 80px;
  border-radius: 50%;
  border: 2px solid #939393;
}
.pro-img-container svg path{
  fill: #f69736;
}
@media only screen and (max-width:768px){
  .profile-right-column{
    width: 95% ;
    margin: auto;
  }
  .profile-contaier {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    border:1px solid #f3f3f3;
    padding: 20px;

  }
  .dashboard-container{
    padding-top: 50px;
  }
  .pro-img-container img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    border: 1px solid #f3f3f3;
  }
  .pro-img-container{
    padding: 10px;
  }
}