.guidelines {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;


}

.guide-title {
    text-align: center;
    font-size: 28px;
    margin-bottom: 20px;
}

.question, .listing_guidelines {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: center;
    align-items: center;

}

.guidelines{
    height: 400px;
}

@media only screen and (max-width: 768px) {
    .guidelines {
        display: flex;
        flex-direction: column-reverse;
    }
    .question, .listing_guidelines {
       width: 100%; 
       margin: 20px 0px;
    }
    .guidelines{
        height: auto;
    }
    
}