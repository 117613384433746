.aadhaar {
  color: #3d5ad0;
  font-size: 18px;
}

.reg-btn {
  border: 1px solid #fff;
  height: 5vh;
  width: 15vh;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.otp-btn {
  border: 1px solid #fff;
  height: 5vh;
  width: 15vh;
  background-color: #4CAF50;
  color: #fff;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
}
.form-container{
  border-radius: 10px;
  background-color: white;
  border:solid 2px #6C6CFD;
  padding: 10px;
width: 65vh;
height: 40vh;
}
.aadhar-head{
  width: 10rem;
}
.inventory-input{
  width: 45vh;
padding: .375rem .75rem;
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
background-color: #fff;
background-clip: padding-box;
border: 1px solid #ced4da;
appearance: none;
border-radius: .375rem;
}
.footer-aadhaar{
  margin-top: 9.82rem;
}
.input-otp{
  width: 20rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;


}
.receive-otp-checkbox{
  margin-left: 10%;
}
@media only screen and (min-width:1024px) and (max-width:768px) {
  .form-container{
  height: 60vh;

  }
  .footer-aadhaar{
    margin-top: 6vh;
  }
  .inventory-input{
    width: 43vh;
 
  }
 
}

