.prop-container {
  position: relative;
}

.contact-btn {
  right: 30px;
  bottom: 30px;
  position: absolute;
}

.action-btns {
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 30px;
  top: 30px;
}
.action-btns > .action-btn:hover {
  transform: scale(1.1);
}
.props-list{
  
  border-radius: 5px;
  border: none;
  box-shadow: 0px 0px 3px grey;
  background-color: #fa983a;
  color: white;
  cursor: pointer;
}