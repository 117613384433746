.bnr-bg{
  background-color: #FF8504;
  /* height: 300px; */
  /* margin-bottom: 30px; */
}
.bnr-content{
  /* margin-left: 100px; */
  padding: 0px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: fit-content;
}

.brand-logo {
    width: 294px;
  height: 101px;
  cursor: pointer;
  }
  
  
  .heading-1{
    border-bottom: 4px solid #ff8504;
    width: max-content;
    font-size: 40px;
    margin-left: 40px;
    font-weight: 700;
  }
  .about-truprops{
    flex-direction: row;
    padding: 50px;
    align-items: center;
    /* height: 550px; */
  }
  .aboutus-left, .aboutus-right{
    padding: 0px 25px;
  }
 .aboutus-right li{
  list-style: none;
  padding-bottom: 20px;
  font-size: 17px;
    font-weight: 600;
 }
.building-image{
  height: 400px;
  width: 550px;
}




  
 

  @media only screen and (max-width:768px){
    .about-truprops{
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0px !important;
    }
    .building-image, .underline {
      /* width: 100%; */
      width: 300px;
      height: 280px;
    }
    .heading-1{
      font-size: 38px;
    }
    .aboutus-left-bnr{
      background: #FF8504;
      width: 100% !important;
      border-radius: 0px !important;
    }
    .about-brand-logo{
      background: none !important;
    }
    .bnr-content{
      margin-left: 0px;
      width: 100%;
      flex-direction: column;
    }
  }