.left-img-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40%;
    padding: 10px;
    /* background-color: #f1f1f9; */
    /* border: solid 1px #f1f1f9; */
    margin: 10px ;
    border-radius: 10px;
}
.listing-type-card {
    position: relative;
    background-color: #8989fd;
    /* width: 20px; */
    color: #fff;
    border-radius: 5px;
    display: flex;
  }
.btn-details{
    border: none;
    background-color: orange;
    box-shadow: 0px 0px 3px orange;
    border-radius: 5px;
    color: #FFFFFF;
}

.right-prop-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
   /* align-items: center; */
    width: 40%;
}

.prop-details-container {
    display: flex;
    flex-direction: row;
    /* margin: 20px; */
    width: 100%;
    justify-content: center;
    /* align-items: center; */
}

.edit-prop-btn{
    background: #4CAF50;
    border: none;
    border-radius: 5px;
    border-color: none;
    color: #FFFFFF;
   
}

.img-container {
    /* position: relative; */
    height: 400px !important;
    /* width: 100%; */
}

.carousal-main-img {
    /* padding: 20px; */
    width: 90%;
    border: none;
    position: relative;
    height: 395px;
    border-radius: 10px;
    /* background-color: #f1f1f9; */
    /* margin: auto; */


}
/* .carousal-main-img>.carousal-img{
  border-radius: 10px;
} */

.nav-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    position: absolute;
    top: 50%;
}

.left-arrow-btn, .right-arrow-btn {
    /* box-sizing: border-box; */
    /* top: 50%; */
/* position: absolute; */
/* left: 10px; */
/* top: 487px; */
/* z-index: 5; */
display: flex;
justify-content: center;
align-items: center;
position: relative;
background: #FF8E23;
border: 1px solid #FFFFFF;
width: 36px;
height: 36px;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 50%;
/* margin-left: -10px; */
/* transform: rotate(-180deg); */
}

.image-count {
    text-align: center;
    /* margin: 10px; */
    color: #6C6CFD;
    font-size: 12px;
    /* background-color: #fff; */
}



.basic-prop-details {
    padding: 30px;
    width: fit-content;
    display: flex;
    /* justify-content: center; */
    flex-direction: column;
    /* background-color: #f1f1f9; */
    margin: 20px;
    /* border-radius: 10px; */
}

.basic-prop-details * {
    margin-bottom: 5px;
}

.price {
    font-size: 20px;
    font-weight: bold;
    color:#6C6CFD;
}

.house-type {
    font-size: 18px;
    color: #212121;
    font-weight:bold;
    display: flex;
    /* font-weight: 500; */

}

.prop-size {
   font-size: 16px; 
   font-weight: bold;
}
.muted-features {
    font-size: 16px;
    color: #9A9898;
    margin-left: 40px;
}
.upload-by {
    font-size: 14px;
    color: #9A9898;
}

.prop-address{
    color: #212121;
}

.prop-location {
    border: 1px solid rgba(0, 0, 0, 0.1);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    height: auto;
margin: 10px 10px;
display: flex;
flex-direction: column;
/* border-radius: 10px; */

}
.prop-location>img{
margin: 1rem;
width: auto;

}

.seller-contact {
    margin: 10px 10px;
    height: 140px;
    border: 1px solid rgba(33, 33, 33, 0.1);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
/* background-color: #fff; */
/* border-radius: 10px; */
justify-content: space-between;
}
.prop-top-details{
  background-color: #fff;
  margin: 5px;
  padding: 10px;
  /* border-radius: 10px; */
}

.seller-contact > button{
    background: #4CAF50;
    border: none;
    border-color: none;
    color: #FFFFFF;
    /* border-radius: 0px 0px 10px 10px ; */
    margin-bottom: 0px;
  
   
}
.btn-details{
float: right;
}
.contact-preview {
    color: #6C6CFD;
}
.prop-desc-card{
    width: 80%;
    display: flex;
    /* justify-content: left; */
    /* left: 200px; */
    justify-content: center;
    align-items: center;
}

.prop-description {
    
    margin: 20px;
    padding: 20px;
    width: 80%;
    border: 1px solid rgba(33, 33, 33, 0.1);
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
/* background-color: #f1f1f9; */
/* border-radius: 10px; */

}
.prop-details-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.left-prop-details, .right-prop-details{
    width: 40%;
}

td {
    margin: 0px 10px;
    padding: 10px;
    width: 50%;

}

td:first-of-type {
    color: #9A9898;
}


.socail-media-icons{
  padding: 10px;
}
.socail-media-icons>svg{
  width: 40px;
  height: 40px;
}

@media only screen and (max-width: 768px) {
    .prop-details-container {
        display: flex;
        flex-direction: column;
        margin: none;
        justify-content: center;
        /* width: 100%; */
    } 
    .left-img-container, .right-prop-container {
        display: flex;
        flex-direction: column;
        align-items: normal;
        width: 90%;
        margin: auto;
    }
    .left-img-container{
      margin: auto;
    }
    .carousal-main-img {
        /* width: 400px; */
        height: 255px;
    
    
    }
    .right-prop-container  {
        width: 100%;
        /* margin: 0px; */
        /* padding: 20px; */
        /* justify-content: center; */
    }
    .basic-prop-details {
      width:90%;
      padding: 20px;
    }
    .prop-desc-card{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .prop-details-row {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .left-prop-details, .right-prop-details{
        width: 100%;
    }
    .prop-description{
      width:100%;
    }
    .seller-contact {
      height: 100%;
    }
    .prop-location {
        /* height: 200px; */
        width: auto;
    }
}

@media only screen and (max-width: 1200px ) and (min-width:767px) {
    .prop-details-container {
        display: flex;
        flex-direction: column;
        margin: none;
        justify-content: center;
        /* width: 100%; */
    } 
    .left-img-container, .right-prop-container {
        display: flex;
        flex-direction: column;
        align-items: normal;
        width: 90%;
        margin: none;
    }
    /* .right-prop-container {
        position: relative;
    } */
    .prop-location {
       width: auto;
    }
    .seller-contact{
        position: absolute;
        bottom: 50px;
        right: 100px;
        height: 47.5%;
        width: 40%;
    }
    .prop-desc-card{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .right-prop-container  {
        /* width: 100%; */
        position: relative;
    }
}


