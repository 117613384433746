.dashboard-card:hover {
  transition: 0.3s all;
  transform: scale(1.04);
}

.dashboard-container{
  /* height: 100vh; */
  /* display: flex;
  flex-direction: row; */
  display: grid;
  grid-template-columns: 150px auto;
  padding: 10px;
}

/* .dashboard-container, .top-card, .task-trasaction{
  display: flex;
  flex-direction: row;
} */


.calender-card {
  height: 240px;
  /* width:400px; */
  box-shadow: 0px 4px 18px 4px rgba(0, 0, 0, 0.25);
border-radius: 11px;
color: #FFFFFF;
padding: 10px;
margin: 20px;
background-color: #31B57D;
}

.payment-overview {
  width:400px;
  height: 200px; 
  background-color: #FFFFFF;
}

.dash-card-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  /* background-color: #2196F3; */
  padding: 10px;
}
/* .sidebar{
  height:90vh;
} */
.nearby-container{
  position: relative;
  display: flex;
  flex-direction: row;

}
.right-column {
  display: grid;
  position: relative;
  /* grid-template-columns: auto auto auto; */
  /* background-color: #2196F3; */
  /* padding: 10px; */
}
.agt-prop-list{
  width: 60%;
}
.limit-alrt-card{
  position: sticky;
  top: 200px;
  right: 20px;
  padding: 10px;
  /* right: 50px; */
  border-radius: 5px;
  background-color: #fff;
  min-width: 300px;
  min-height: 200px;
}
.zl{
  border: 5px solid rgb(175, 44, 0);
  animation: blinker 1s linear infinite;
  box-shadow: 0px 4px 18px 4px rgb(255, 94, 0);
}
.ol{
  border: 5px solid rgb(193, 167, 0);
  /* box-shadow: 0px 4px 18px 4px rgb(255, 221, 0); */
}
.gl{
  border: 5px solid rgb(0, 148, 25);
  /* box-shadow: 0px 4px 18px 4px rgb(140, 255, 0); */
}
.delete-confirmation{
  position: absolute;
  width: 90%;
  background-color: #000;
  opacity: .9;
  top: 5%;
  height: 90%;
  left: 5%;
  color: #fff;
  text-align: center;
  border-radius: 8px;
  padding-top: 5%;
}
.del-btn-bkt{
  background-color: transparent;
  border: none;
  position: absolute;
  top: 2px;
  right: 2px;
  padding: 5px;
}
@keyframes blinker {
  50% {
    border-color: transparent;
  }
}
@media only screen and (max-width: 768px) {

  .sidebar{
    background-color: #FFFFFF;
    position: -webkit-sticky;
  position: sticky;
  bottom: 0px;
  padding: 5px;
  z-index: 30;
  
  }
  .right-column {
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    /* justify-content: center; */
    align-items: center;

  }
  .dashboard-container{
    display: flex;
    flex-direction: column;
  }
  .dashboard-card, .calender-card, .upcoming-task-container, .trasaction-details, .payment-overview {
    width: 90% !important;
    /* margin: 0px !important; */

  }
  .icon-card {
    margin: 5px !important;
  }
  .dash-card-container {
    display: grid;
    grid-template-columns: auto;
  }
  .nearby-container{
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  
  }
  .agt-prop-list{
    width: 100%;
  }
  .limit-alrt-card{
    margin: 20px auto;
  }
}
