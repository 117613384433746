.agent-nav-links1-mob{
  display: none;
}



@media (max-width: 768px) {
  .agent-nav-links1-mob{
    display: block;
  }

  .agent-hamburger1 {
    display: block;
  position: relative;
  border: 100px;
  cursor: pointer;
  }
  .agent-hamburger1:enabled ~ .agent-menu1 {
    display: block;
  position: relative;
  background-color: aqua;
  }

  .nav-menus-mob{
    background-color: #f0f0f0;
    width: 50%;
    border-radius: 10px;
    margin-left: 10px;
  }

    .nav-menu-mob.active {
      background-color: #4caf50;
      color: #fff;
    }
    .nav-menu-mob.active:hover {
      background-color: #4caf4fe2;
    }
    .nav-menu-mob:hover {
      background-color: #fff;
      cursor: pointer;
    }

}