.footer {
  background-color: #ff8504;
  height: 275px;
  color: white;

  /* font-size: 40px; */
}

.footer-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 30px;
}

.footer-column3,
.footer-column1,
.footer-column2 {
  display: flex;
  flex-direction: column;
  /* width: 30%; */
  padding: 20px;
}
.facebook {
  cursor: pointer;
}
.youtube {
  cursor: pointer;
}
.insta {
  cursor: pointer;
}
.twit {
  cursor: pointer;
}

.footer-bottom {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sm-icons {
  display: flex;
  flex-direction: row;
  gap: 1em;
}
.developed {
  font-size: 18px;
  color: #fff;
  padding-right: 10px;
}
.footer-link-items:hover {
  color:black;
  cursor: pointer;
}

.sm-icons > div {
  margin: 10px;
}

@media only screen and (max-width: 768px) {
  .footer-row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .footer-column3,
  .footer-column1,
  .footer-column2 {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    padding: 20px;
    width: 70%;
  }
  .footer {
    height: auto;
  }
}
