

/* Responsivity */

@media only screen and (max-width: 990px) {
    body {
        background-size: 130%;
    }
}
@media only screen and (max-width: 740px) {
    body {
        background-size: 175%;
    }
}
@media only screen and (max-width: 555px) {
    body {
        background-size: 245%;
    }
}
@media only screen and (max-width: 420px) {
    body {
        background-size: 265%;
    }
}
@media only screen and (max-width: 380px) {
    body {
        background-size: 320%;
    }
}
.btn-white {
    border-radius: 50px;
    color: orange;
    background-color: #fff;
    padding: 8px 40px;
    cursor: pointer;
    border: 2px solid orange !important
}
.btn-white:hover {
    color: #fff;
    background-image: linear-gradient(to right, #FFD54F, orange)
}
button:focus {
    -moz-box-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    outline-width: 0
} 
.forget-container {
    width: 100%;
    display: flex;
    margin-top: .5em;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    font-size: .8rem;
    cursor: pointer;
    color: crimson;
}
.forget-container p{
    margin: 0;
}
.login-vector{
    margin-top: 2em;
    width: 100%;
    height: auto;
}
.login-box{
    width: 25rem;
    /* height: max-content; */
}
.login-title{
    color: #6C6CFD;
}
.login-index{
    border-bottom: 2px solid #FFD54F
    /* font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; */
}
.btn-login{
    background-color: #4CAF50;
    border: none;
    border-radius: 0.25rem;
    color: #fff;
}
.login-image{
    height: 8rem;
justify-content: end;
align-items: end;
display: flex;
}
.login-text:hover{
    cursor: pointer;
    border-bottom : 2px solid #FFD54F;
}