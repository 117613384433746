.my-acc-card-container {
  position: relative;
  padding: 8px;
  width: auto;
  margin-right: 16px;
}
.my-acc-card {
  position: relative;
  min-width: 350px;
}
.my-acc-card-container > .my-acc-card {
  -webkit-box-shadow: 1px 1px 19px 3px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 1px 1px 19px 3px rgba(0, 0, 0, 0.24);
  box-shadow: 1px 1px 19px 3px rgba(0, 0, 0, 0.24);
  border-radius: 16px;
  overflow: hidden;
}

.my-acc-card > .profile-sec {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
  flex-direction: column;
}

.my-acc-card > .profile-sec > .profile-image {
  background-color: var(--secondary-color);
  width: 150px;
  height: 150px;
  border-radius: 75px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.my-acc-card > .profile-sec > .profile-name {
  margin: 16px 0;
}
.whole-containers {
  margin-top: 60px;
  z-index: -4;
  position: relative;
  top: 0;
  /* overflow: scroll; */
}
.verified_icon {
  width: 25px;
  height: auto;
}
.nav-menus {
  margin-bottom: 2rem;
}

.nav-menu {
  cursor: pointer;
  margin: 8px 0;
}

.nav-menu.active {
  background-color: #4caf50;
  color: #fff;
}
.nav-menu.active:hover {
  background-color: #4caf4fe2;
}
.nav-menu:hover {
  background-color: #f0f0f0;
}
.pro_pic_con {
  display: flex;
  border: 1px solid;
}
.edit_profile {
  width: 40%;
  margin: 1em auto;
  border-radius: 10px;
  text-align: center;
  padding: 0.3em 0.7em;
  cursor: pointer;
}
.edit_profile:hover {
  background-color: var(--primary-color);
  color: white;
}
@media (max-width: 768px) {
  .my-acc-card-container {
    display: none;
  }
}
