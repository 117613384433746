.main-container {
    position: relative;
    background-image: url("../../assets/homebnr.png");
    /* url("https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8c29mYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"); */
    height: 350px;
    background-size: cover;
    /* position: absolute; */
    /* display: flex;
    flex-direction: row; */
    z-index: 1;
   
  }
  
  .banner_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* width: 100%; */
    /* height: 100%; */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0px, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%) rgba(0, 0, 0, 0.4);
    z-index: -1;
  
  }
  
  .banner-row {
    display: flex;
    flex-direction: row;
    height: 350px;
    z-index: 1;
  }
  
  .left-bnr {
    background: radial-gradient(256.93% 236.2% at 34.38% -24.29%, #FF8504 32.99%, #FF9B4B 68.47%);
    width: 50%;
    height: 350px;
    /* z-index: 1; */
    padding-top: 20px;
    border-radius: 0 45% 0 0;
    display: flex;
    justify-content: center;
  }
  
  .left-bnr-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin-top: 25%; */
  }
  .brand-logo {
    width: 294px;
  height: 101px;
  }
  
  .contact-form{
    /* height: 600px; */
    width: 90%;
    /* box-shadow: 0px 0px 4px 4px grey; */
    background-color: white;
    margin-top: 20px;
    margin-left: 100px;
    justify-content: center;
  }
  .get-touch{
    /* margin-left: 50px; */
    font-size: 30px;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-items: center;
  }
  .icons-allcontents{
    /* margin-left: 50px; */
    margin-top: 20px;
    font-size: 16px;
    font-weight: 400;
  }
  .icon-contents1{
    margin-top: 30px;
  }
  .icons-contents{
    margin-left: 50px;
    gap: 15px;
    margin-top: 30px;
  

  }
  .contact-truprops{
    display: flex;
    flex-direction: row;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
  }
  .contact-left, .contact-right{
    padding: 0px 20px;
  }
  .contact-input{
    margin-left: 50px;
  }
  .contact-name{
    border-radius: 8px;
  }
  .contact-message{
    border-radius: 8px;
    height: 200px;
    width: 390px;
    padding: 100px;
  }
  .location{
    height: 20px;
    width: 20px;
  }
  .calls{
    height: 20px;
    width: 20px;
    margin-top: 10px;
  }


  .contactus-img{
    height: 450px;
    width: 450px;
  }
  .contact-right{
    border-left: solid 2px #6C6CFD;
  }
  .contact-form-inputs{
    padding: 20px 10px;
    margin: auto;
  }
  .contact-form-inputs button{
    width: 100%;
  }
  .contact-form-inputs label{
    display: none;
  }
  .contact-form-inputs p{
    color: crimson;
  }
  .contact-form-inputs>input, .contact-form-inputs>textarea{
    font:inherit;
    display: flex;
    flex-direction: column;
    margin: 20px 0px;
    width: 100%;
    border-radius: 5px;
    background-color: #fff;
    border: 1px solid #939393;
    padding: 5px;
  }
  .contact-form-inputs>input:focus, .contact-form-inputs>textarea:focus{
  outline: 1px solid #6C6CFD;
  }

  @media only screen and (max-width:768px){
    .contactus-img{
      height: 300px;
      width: 300px;
    }
    .contact-truprops{
      display: flex;
      flex-direction: column !important;
    }
    .contact-right{
      border-left: none;

    }

  }