.main-container {
    position: relative;
    background-image: url("../../assets/homebnr.png");
    /* url("https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8c29mYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"); */
    height: 450px;
    background-size: cover;
    /* position: absolute; */
    /* display: flex;
    flex-direction: row; */
    z-index: 1;
   
  }
  
  .banner_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* width: 100%; */
    /* height: 100%; */
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0px, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%) rgba(0, 0, 0, 0.4);
    z-index: -1;
  
  }
  
  .banner-row {
    display: flex;
    flex-direction: row;
    height: 350px;
    z-index: 1;
  }
  
  .left-bnr {
    background: radial-gradient(256.93% 236.2% at 34.38% -24.29%, #FF8504 32.99%, #FF9B4B 68.47%);
    width: 50%;
    height: 350px;
    /* z-index: 1; */
    padding-top: 20px;
    border-radius: 0 100% 0 0;
    display: flex;
    justify-content: center;
  }
  
  .left-bnr-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* margin-top: 25%; */
  }
  .brand-logo {
    width: 294px;
  height: 101px;
  
  }
  
  
.pricing-cards{
    /* height: 400px; */
    width: 100%;
    /* box-shadow: 0px 0px 4px 4px grey; */
    background-color: white;
    justify-content: center;
}

.card-1{
    /* height: 360px; */
    border-radius: 10px;
    width: 385px;
    background-color: white;
    margin-top: 20px;
    margin-left: 20px;
    border:solid 2px #6C6CFD;
    padding: 3px;
    /* box-shadow: 0px 0px 4px 4px grey; */
}
.grid-container {
    display: grid;
    /* height: 360px; */
    /* margin-top: 50px; */
    align-content: center;
    grid-template-columns: auto ;
    gap: 4px;
    /* background-color: orange; */
    /* padding: 10px; */
  }
  
  .grid-container > div {
    background-color: rgba(255, 255, 255, 0.8);
    text-align: center;
    padding:1px 0;
    font-size: 24px;
  }
  .price-1{
    background-color: #FF8504;
    font-weight: bolder;
    font-size: 30px;
  }
  .pricing-1{
    background-color: orange;
    font-weight: bolder;
    font-size: 30px;
  }
  .subscribe-btn{
    background-color: #6C6CFD;
  }

  @media only screen and (max-width:768px){
    .all-cards{
      display: flex;
      flex-direction: column;
    }
    .pricing-cards{
      height: 100% !important;
      /* box-shadow: 0px 0px 4px 4px grey; */
      background-color: white;
      margin-top: 50px;
      margin-bottom: 50px;
      margin-left: 0px;
      justify-content: center;
  }
  }