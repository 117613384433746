.re-navbar-container {
  width: 100%;
  /* background-color: var(--primary-color); */
  background: rgb(246,169,96);
  background: linear-gradient(180deg, rgba(246,169,96,1) 7%, rgba(244,152,62,1) 46%, rgba(250,152,58,1) 100%);
  /* height: 250px; */
  border-bottom-left-radius: 100% 60%;
  border-bottom-right-radius: 100% 60%;
  justify-content: center;
  align-items: center;
  padding: 0.4rem;
}

.re-navbar-menus {
  display: flex;
  justify-content: flex-end;
}

.re-navbar-menus > .re-navbar-menu {
  color: white;
}

.re-navbar-menu {
  padding: 0.5rem;
  border-radius: 10px;
}
.re-navbar-menu.green-bg {
  background-color: teal;
}
.re-navbar-menu.light-bg {
  background-color: #fff;
  color: var(--primary-color);
}

.re-search-bar-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* * profile dropdown */
.profile-dropdown:click {
  background-color: #999;
}
