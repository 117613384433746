/* .main-container {
  height: 100vh;
  overflow-y: hidden;
  position: relative;
} */
/* .main-container > .img-container {
  height: 100%;
}
.main-container > .img-container > img {
  height: 100%;
}
.main-container > .img-container > .search {
  justify-content: end;
  text-align: end;
} */

.header1 {
  position: absolute;
  height: 50px;
  width: 100%;
  /* top: 0;
  left: 0; */
  padding: 5px;
  z-index: 3;
  display: flex;
  justify-content:flex-end;
  margin-top: 5px;
  align-items:center;
  color: #fff;
}

.header-content1 {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  top: 0px;
  /* width: 50%; */
  /* height: 60px; */
  padding: 10px;
}
.header-content1>*{
  margin: auto;
  height: 100%;
}

.download-app, .list-property {
  margin: 0px 30px
}

.main-container1 {
  position: relative;
  background-image: url("../../assets/homebnr.png");
  /* url("https://images.unsplash.com/photo-1493663284031-b7e3aefcae8e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8c29mYXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=900&q=60"); */
  height: 712px;
  background-size: cover;
  /* position: absolute; */
  /* display: flex;
  flex-direction: row; */
  z-index: 1;
 
}

.banner_overlay1 {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* width: 100%; */
  /* height: 100%; */
  background: linear-gradient(to top, rgba(0, 0, 0, 0.8) 0px, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0.8) 100%) rgba(0, 0, 0, 0.4);
  z-index: -1;

}

.banner-row1 {
  display: flex;
  flex-direction: row;
  height: 712px;
  z-index: 1;
}
.list-property{
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: end;
  
}
.free-btn{
background-color: #6C6CFD;
color: #fff;
border-radius: 2px;
width: max-content;
padding: 0px 5px;
font-size: 12px;

}
.download-app{
  cursor: pointer;
  align-self: end;
}

.left-bnr1 {
  background: radial-gradient(256.93% 236.2% at 34.38% -24.29%, #FF8504 32.99%, #FF9B4B 68.47%);
  width: 50%;
  /* height: 712px; */
  /* z-index: 1; */
  padding-top: 20px;
  border-radius: 0 45% 0 0;
  display: flex;
  justify-content: center;
}

.left-bnr-content1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* margin-top: 25%; */
}


.bnr-title-happy {

font-style: normal;
font-weight: 600;
font-size: 72px;
margin-bottom: 20px;
/* line-height: 144px; */
/* identical to box height */

text-align: center;

color: #FFFFFF;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.brand-logo {
  width: 294px;
height: 101px;
}

.bnr-title {
  /* font-family: 'Poppins'; */
font-style: normal;
font-weight: 300;
font-size: 36px;
/* line-height: 72px; */
text-align: center;

color: #FFFFFF;

text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.list-your-prop {
  width: max-content;
height: 45px;
/* left: 229px;
top: 545px; */
border: none;
margin: 30px 0px;

background: #6C6CFD;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px -4px 10px rgba(0, 0, 0, 0.25);
border-radius: 6px;
font-style: normal;
font-weight: 500;
font-size: 22.78px;
line-height: 34px;
padding: 5px;
color: #FFFFFF;
}

.icons-container {
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 30px 0px;
  padding: 20px;
  justify-content: space-around;
}

figure {
  text-align: center;  
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-bnr {
  width: 50%;
  height: 100%;
  z-index: 1;
  padding-top: 20px;
  background: none;
  display: flex;
  justify-content: center;
}

.search-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .banner-row1 {
    display: flex;
    flex-direction: column;
    height: 100%;
  
  }
  .download-app{
    margin: 0px 10px;
    /* bottom: 0px; */
  }

  .brand-logo {
    width: 294px;
  height: 120px;
  }

  .left-bnr1, .right-bnr {
    height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: none;
  align-items: center;
  }

  .left-bnr-content1 {
    margin-top: 20%;
  }

  .left-bnr1 {
    /* margin-top: 25%; */
  }

  .left-bnr1 {
    /* border-radius: 20% 20% 0 0; */
  }
  .brand-logo {
    background: radial-gradient(256.93% 236.2% at 34.38% -24.29%, #FF8504 32.99%, #FF9B4B 68.47%);
    padding: 10px;
    margin-top: 50px;
    border-radius: 50px 5px  50px;
  }
.icons-container {
  display: none;
}

  .header-content {
   
    width: 100%;
  }
  .download-app{
    display: none;
  }

/* .left-bnr-content {
  padding: 20px;
  margin: 20px;
} */

} 

.main-container1 > .left-container {
  /* position: absolute; */
  /* left: 0;
  top: 0; */
  background: radial-gradient(#ff8504, #ff9b4b);
  height: 712px;
  width: 50%;
  z-index: 1;
  padding-top: 20px;
  border-radius: 0 45% 0 0;
}
.main-container1 > .right-container {
  /* position: absolute; */
  left: 50%;
  top: 0;
  height: 100vh;
  width: 50%;
  z-index: 1;
  padding-top: 20px;
  border-radius: 0 45% 0 0;
}

.logo-container {
  width: 40%;
  align-items: center;
}

.logo-container > img {
  width: 100%;
  margin-left: 170px;
  margin-top: 120px;
}
.left-container > .container > .quotes {
  margin-top: 10px;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 2px #868585;
  font-size: 50px;
  font-weight: 200;
}
.left-container > .container > .quotes > .happy {
  font-weight: bold;
  font-size: 100px;
}
.left-container > .container > .button > .list {
  margin-left: 250px;
  background: var(var(--secondary-color));
  box-shadow: 3px 3px rgb(109, 108, 108);
  border: none;
}
.right-container > .container {
  margin-top: 45%;
}

.nav-container {
  display: flex;
  flex-direction: row;
  float: right;
  margin-right: 30px;
  align-items: center;
}

.nav-container > .menu {
  margin: 0 0 0 30px;
  font-size: large;
  font-weight: 500;
  color: #000;
}

.nav-container > .menu.list-free-item {
  display: flex;
  flex-direction: column;
}
.second-container {
  height: 600px;
}
