.new-event {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.add-btn {
  width: 40px;
  height: 40px;
  background-color: #4caf50;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.task-cards {
  width: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  /* height: 200px; */
  border: 2px solid rgb(243, 242, 242);
  box-shadow: 1px 1px 7px 2px rgba(0, 0, 0, 0.25);
  padding: 10px;
  margin: 20px;
}

.avatar-column,
.customer-contact {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* justify-content: center;
  align-items: center; */
  /* width: 25%; */
}
.avatar-column {
  width: 25%;
}

.task-card-details {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  width: 40%;
  font-size: 14px;
  color: #747474;
  padding: 10px;
}
.task-card-details > div,
.customer-contact > div {
  margin-bottom: 10px;
}
.span {
  margin-left: 10px;
}
.cutomer-name {
  color: #000000;
  font-size: 1.5rem;
}
.cust-location {
  color: #6c6cfd;
}

.del-reschedule {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* .cust-location, .dt-time, .delete, .reshedule, .navigate {
  display: flex;
  flex-direction: row;
} */

.customer-contact {
  /* border-left: 1px solid #c0baba; */
  margin: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  width: 30%;
  font-size: 1rem;
  color: #000000;

  /* align-items: center; */
}

.call-cust {
  display: flex;
  /* flex-direction: row; */
  /* justify-content: space-between; */
  align-items: center;
}
.phone-num {
  color: #747474;
}

.vl-task {
  border-left: 1px solid #c0baba;
  height: 18 0px;
  margin-left: 350px;
  font-size: 14px;
  font-weight: bold;
}
.new-event {
  margin-top: 50px;
  margin-left: 100px;
  font-weight: bold;
  font-size: 14px;
}
.title {
  text-align: center;
}
.form-label {
  font-weight: bolder;
  color: gray;
}





/* #new task */

.tasklist-card{
  height: 300px;
  width: 900px;
  box-shadow: 1px 1px 4px grey;
  /* border-radius: 10px; */
  background-color: #fff;
  /* width: 60%; */
  display: flex;
  flex-direction: row;
  /* justify-content: center; */
  align-items: center;
  border-radius: 20px;
  /* height: 200px; */
  border: 2px solid rgb(243, 242, 242);
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
  padding: 20px;
  margin: 50px;
  margin-left: 15%;
}
.profileimages-1{
  box-shadow: 1px 1px 3px 1px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
}
.profile-name{
  font-size: 35px;

}
.span-locations{
  color: #6C6CFD;
}
.comment-box{
  height: 30px;
  width: 250px;
  background-color: #F3EFEF;

}
.time-date{
  gap: 40px;
}
.straight-line{
  height: 175px;
  width: 1px;
  background-color:#C0BABA ;
}
.profile-tasklist{
  margin-left: 20px;
}
.types-profile{
  margin-left:30%;
}
.delete-lists{
  display: flex;
}
.del-reschedule{
  gap:60px;
}
/* .tasklist-allcards{
  display: flex;
} */

@media only screen and (max-width: 768px) {
  .task-cards {
    width: 100%;
    flex-direction: column !important;
  }
  .avatar-column {
    display: none;
  }
  .task-card-details {
    width: 100%;
  }

  .customer-contact {
    width: 100%;
    border-left: none;
    /* border-top: 1px solid #C0BABA; */
    background-color: #f3f1f1;
    border-radius: 0px 0px 20px 20px;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
}

.hand-hover {
  cursor: pointer;
}
