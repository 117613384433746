.discover-section-container {
  /* padding: 10px; */
  display: flex;
  flex-direction: row;
}

.discover-section-container > .left-container {
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 25px;
  width: 40%;
  align-items: center;
  /* min-width: 300px; */
}
.discover-section-container > .right-container {
  /* background-color: blue; */
  width:60%;
  
  /* margin: 10px; */
}
.prop-container {
  /* margin-left: 200px; */
  display: flex;
  align-items: flex-end;
}

.next-prop-container {
  width: 300px;
  height: 200px;
  overflow: hidden;
}

.next-prop-container > img {
  width: 100%;
  height: 100%;
}
.discover-prop-control-btn-container {
  margin-left: 180px;
  color: var(var(--secondary-color));
  width: 100px;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
}

@media only screen and (max-width: 768px) {
  .discover-section-container {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
  }
.discover-prop-control-btn-container {
  margin-left: 0;
}

  .discover-section-container > .left-container, .discover-section-container > .right-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    align-items: center;
  }

  .prop-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* justify-content: center;
    padding: 10px; */
  }


  .next-prop-container {
    display: none;
  }
}
