
  

  .otp-input-container {
    height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  }
  .action-command {
    font-family: sans-serif, Helvetica, Arial;
  }
  .forget-form{
    display: flex;
    flex-direction: column;
    gap: 1.5em;
  }
  /* button {
    border: 0;
    padding: 0.5em 0;
    border-radius: 10px;
    background-color: #fa983a;
    width: 50%;
  } */
  .forget-input{
    /* border: 1px; */
    border-radius: 0;
    width: 75%;
    padding: 0.6em 0.4em;
  }
.forget-password{
  width: 60vh;
}
