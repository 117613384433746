.custom-file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.custom-file-input + label {
  font-size: 1.25em;
  font-weight: 700;
  color: white;
  background-color: black;
  display: inline-block;
}

.custom-file-input:focus + label,
.custom-file-input + label:hover {
  background-color: red;
}
.custom-file-input + label {
  cursor: pointer; /* "hand" cursor */
}
.custom-file-input:focus + label {
  outline: 1px dotted #000;
  outline: -webkit-focus-ring-color auto 5px;
}
.custom-file-input + label * {
  pointer-events: none;
}

.custom-file-input-label > div {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom-file-input-label > div > div {
  font-size: 0.75em;
  border-radius: 10px;
  border-width: 4px;
  width: 100px;
  height: 100px;
  border-color: grey;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  color: grey;
  opacity: 0.3;
  text-align: center;
}
.image-container {
  display: flex;
  justify-self: center;
  align-items: center;
  width: 200px;
  overflow: hidden;
  height: 200px;
}

.svg{
  display: block;
  margin: 20px auto;
  max-width: 100%;
}

.svg-circle-bg {
  fill: none;
}

.svg-circle {
  fill: none;
}

.svg-circle-text {
  font-size: 2rem;
  text-anchor: middle;
  fill: #fff;
  font-weight: bold;
}
