.profile-dropdown-container {
  margin-top: 0.5rem;
  width: 300px;
  border-radius: 16px;
}

.profile-dropdown-menu {
  padding: 0.5em;
  cursor: pointer;
  margin: 0 1em;
  border-radius: 10px;
}
.profile-dropdown-menu.profile-menu {
  border-radius: 16px 16px 0 0;
  overflow: hidden;
}
.profile-dropdown-menu.highlight-hover:hover {
  background-color: var(--success-color);
  color: #fff;
}
.profile-dropdown-menu::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  top: -10px;
  right: 30px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.profile-dropdown-menu.sign-out {
  color: #a80808;
  border-radius: 0 0 16px 16px;
}
.profile-dropdown-menu.sign-out:hover {
  color: orangered;
  text-shadow: 0px 0px 4px orangered;
}

.dropdown-menu > .container {
  padding: 10px;

  flex-direction: column;
}
